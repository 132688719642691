import postcss from 'postcss'
import nested from 'postcss-nested'

document.addEventListener('DOMContentLoaded', () => {
  if(!CSS.supports('selector(&)')){
    const styleElements = document.querySelectorAll('style.postcss')
    styleElements.forEach(async styleElement => {
      const res = await postcss([nested]).process(styleElement.textContent ?? '', {from: undefined})
      const newStyleElement = document.createElement('style')
      newStyleElement.appendChild(document.createTextNode(res.css))
      styleElement.parentNode?.replaceChild(newStyleElement, styleElement)
    })
  }
})
